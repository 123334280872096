<script>
import { mapState } from 'vuex'

export default {
  name: 'Emptymessage',
  computed: {
    ...mapState(['fetching'])
  }
}
</script>

<template>
  <div
    v-if="!fetching"
    class="empty-message"
  >
    <div class="center">
      <slot />
    </div>
  </div>
</template>

<style src="@/assets/styles/themes/default/emptymessage.css"></style>
